import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Layout from "../components/layout"
import SEO from "../components/seo"
import InstallationServices from "../components/installationServices"
import TurnkeyFraming from "../components/turnkeyFraming"

const EngineeringServices = () => {
  const data = useStaticQuery(graphql`
    query {
      photo1: file(relativePath: { eq: "Floor_plan.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            src
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Engineering Services" />
      <Helmet
        bodyAttributes={{
          class: "page-engineering-services",
        }}
      />
      <Row>
        <Col sm={12}>
          <h1>Engineering Services</h1>
        </Col>
        <Col md={9}>
          <h3>
            Where would my building project be without Plans and Specifications?
          </h3>
          <img
            className="img-fluid float-right ml-3 mb-3"
            src={data.photo1.childImageSharp.fluid.src}
            alt="Interior Construction"
          />
          <p>
            This is the question you should ask if you know what you want to
            build and how much you can spend. Without these two items; your
            project will not turn out 100% how you pictured and you will have
            spent more than budgeted. Plans and specifications are the single
            most important aspect of a project’s cost.
          </p>
          <p>
            Capital Structures has partnered with structural engineers to
            develop a method of producing plans that are well thought out from
            Roof to Concrete. We are able to review the architectural drawings
            and design the wood structure to suit those architectural
            requirements. With our component designs complete, our structural
            engineer is able to complete the process of design by developing
            sheer walls and foundation systems for project specific load
            conditions. These paths of design reduce “over engineering” by
            creating “value engineering” from the beginning of the project.
          </p>
          <p>
            This method of structural engineering creates industry approved
            design work that can be used by everyone. Our structural engineer
            partners design projects out of wood every day; they know how to
            produce a cost efficient product.
          </p>
          <h3>Custom Engineering at Its Best</h3>
          <ul>
            <li>Taking your project from concept to reality.</li>
            <li>Your ideas are our priority.</li>
            <li>Meet with one of our designers.</li>
          </ul>
          <Button href="/contact" variant="primary">
            Get Started Today
          </Button>
        </Col>
        <Col md={3}>
          <TurnkeyFraming />
          <InstallationServices />
        </Col>
      </Row>
    </Layout>
  )
}

export default EngineeringServices
